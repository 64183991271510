import styled from 'styled-components'
import { PHONE } from '../constants/measurements'
import { themeColors } from '../constants/colors'

export const ContainerRecordings = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`

export const UploadIconCircle = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(235, 235, 235);
  margin-top: 100px;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`

export const Wrap = styled.div`
  width: 500px;
  margin-top: 20px;
  @media (max-width: ${PHONE}) {
    margin-top: 10px;
    width: 90%;
  }
`

export const WrapCol = styled.div`
  width: 500px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${PHONE}) {
    margin-top: 10px;
    width: 90%;
  }
`

export const FileName = styled.div`
  color: ${themeColors.textColor};
  margin-top: 20px;
  margin-bottom 20px;
`

export const ImagePreview = styled.img`
  width: 350px;
  height: 200px;
  margin-bottom: 20px;
  object-fit: contain;
  background-color: #000000;
`

export const HeaderWrap = styled.div`
  padding: 0 20px;
`

export const Image = styled.div<{ img: string }>`
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 3px solid ${themeColors.primary};
  ${({ img }): string => `background-image: url(${img});`}
`

export const ImageWraper = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Spacer = styled.div`
  margin-top: 20px;
`

export const TrainerImage = styled.img`
  width: 40px;
  height 40px;
  margin: 0;
  margin-right: 15px;
  border-radius: 50%;
`

export const TrainerRow = styled.div`
  display: flex;
  align-items: center;
`
