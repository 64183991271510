import React from 'react'
import { Link, navigate } from 'gatsby'
import { IInputTypeProps } from '../util/Input'
import {
  ErrorMessage,
  Text,
  UnusuallyLongRequestMessage,
  Row,
  Col,
} from '../../shared'
import { BtnSelect } from '../util/BtnSelect'
import { LIVE_SCHEDULE_ROUTE, TRAINER_LOGIN } from '../../../constants/routes'
import {
  isDisabledGenerator,
  defaultErrorMsg,
  getOptionsFromEnum,
  handleChangeGenerator,
} from '../util/helpers'
import { EAccountType } from '../../../types/user'
import { IRequestState } from '../util/types'
import { fetchRegister } from '../../../util/fetch'
import { isOnBrowser } from '../../../util/browser'
import * as session from '../../../util/session'
import { M1 } from '../../../constants/measurements'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { themeColors } from '../../../constants/colors'

interface IRegisterBody {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}

const AccountTypeOptions = getOptionsFromEnum(EAccountType)

interface IRegsiterAccountType {
  type: EAccountType
}

type IRegisterState = IRegisterBody & IRequestState & IRegsiterAccountType

interface IProps {
  context?: 'live'
  isTrainer?: boolean
}

class LiveRegister extends React.Component<IProps, IRegisterState> {
  private static inputTypes: { [s in keyof IRegisterBody]: IInputTypeProps } = {
    firstName: {
      autoFocus: true,
      label: 'First name',
      type: 'text',
      required: true,
      autoComplete: 'given-name',
    },
    lastName: {
      label: 'Last name',
      type: 'text',
      required: true,
      autoComplete: 'family-name',
    },
    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
      autoComplete: 'new-password',
    },
    confirmPassword: {
      label: 'Confirm password',
      type: 'password',
      required: true,
      autoComplete: 'current-password',
    },
  }

  private static isDisabled = isDisabledGenerator(LiveRegister.inputTypes)

  constructor(props: {}) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      email: '',
      pending: false,
      type: EAccountType.Athlete,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  public componentDidUpdate(_: {}, prevState: IRegisterState): void {
    if (!isOnBrowser()) return

    // Scroll to top of the screen to show user there is an error
    const { error } = this.state
    const { error: prevError } = prevState
    if (error && error !== prevError) {
      window.scroll(0, 0)
    }
  }

  handleChangeInput = (e: any, name: any) => {
    // @ts-ignore
    this.setState({ [name]: e.target.value })
  }

  private handleChange = handleChangeGenerator(this)

  private handleChangeType(type: EAccountType): void {
    this.setState({ type })
  }

  private handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    if (LiveRegister.isDisabled(this.state)) return

    // Frontend error checking
    const {
      type,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    } = this.state
    if (password !== confirmPassword) {
      return this.setState({ error: 'Passwords do not match.' })
    }

    // Send request
    this.setState({ pending: true, error: undefined })
    fetchRegister({ type, firstName, lastName, email, password })
      .then(({ access_token: accessToken, refresh_token: refreshToken }) => {
        try {
          session.login(accessToken, refreshToken, true)
          if (this.props.context === 'live') {
            navigate(LIVE_SCHEDULE_ROUTE)
          }
        } catch (err) {
          this.setState({
            error: (err && err.message) || defaultErrorMsg,
            pending: false,
          })
        }
      })
      .catch((err: Error) => {
        this.setState({
          pending: false,
          error: (err && err.message) || defaultErrorMsg,
        })
      })
  }

  public render(): React.ReactElement {
    const { pending, error, type } = this.state

    const isLoggedIn = session.isLoggedIn()
    if (isLoggedIn) {
      navigate(LIVE_SCHEDULE_ROUTE)
    }

    const isDisabled = LiveRegister.isDisabled(this.state)

    return (
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
          this.handleSubmit(e)
        }
      >
        <ErrorMessage message={error} />
        <UnusuallyLongRequestMessage pending={pending} />

        {this.props.context !== 'live' && (
          <BtnSelect
            label="Account Type"
            isSingleSelect
            options={AccountTypeOptions}
            values={[type]}
            numCols={2}
            onClick={(values): void =>
              this.handleChangeType(values[0] as EAccountType)
            }
          />
        )}

        <Row margin={M1}>
          <Col sm={12} lg={6} margin={M1}>
            <TextField
              value={this.state.firstName}
              label="First Name"
              onChange={value => this.handleChangeInput(value, 'firstName')}
              variant="outlined"
              style={{ width: '100%', marginBottom: 20 }}
            />
          </Col>
          <Col sm={12} lg={6} margin={M1}>
            <TextField
              value={this.state.lastName}
              label="Last Name"
              onChange={value => this.handleChangeInput(value, 'lastName')}
              variant="outlined"
              style={{ width: '100%', marginBottom: 20 }}
            />
          </Col>
        </Row>

        <TextField
          value={this.state.email}
          label="Email"
          onChange={value => this.handleChangeInput(value, 'email')}
          variant="outlined"
          type="email"
          style={{ width: '100%', marginBottom: 20 }}
        />

        <TextField
          value={this.state.password}
          label="Password"
          onChange={value => this.handleChangeInput(value, 'password')}
          variant="outlined"
          type="password"
          style={{ width: '100%', marginBottom: 20 }}
        />

        <TextField
          value={this.state.confirmPassword}
          label="Confirm Password"
          onChange={value => this.handleChangeInput(value, 'confirmPassword')}
          variant="outlined"
          type="password"
          style={{ width: '100%', marginBottom: 20 }}
        />

        <Button
          variant="contained"
          disabled={isDisabled || pending}
          type="submit"
          color="primary"
          style={{
            textTransform: 'capitalize',
            marginTop: 10,
            marginBottom: 20,
          }}
          fullWidth
        >
          {pending ? 'Signing up...' : 'Sign up'}
        </Button>

        <Text sm color={themeColors.textColor}>
          Already have an account?{' '}
          <Link style={{ color: themeColors.primary }} to={TRAINER_LOGIN}>
            Login here.
          </Link>
        </Text>
      </form>
    )
  }
}

export default LiveRegister
