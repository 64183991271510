import React from 'react'
import LiveRegister from '../../components/forms/live/LiveRegister'
import { H1, QueryParamMessage } from '../../components/shared'
import Layout from '../../templates/LiveLayout'
import * as S from '../../styles/login-live.styles'
import { PageRendererProps } from 'gatsby'
import * as Colors from '../../constants/colors'
import { withErrorHandler } from '../../components/errorHandler'

const SignUpPage = (props: PageRendererProps): React.ReactElement => (
  <Layout title="" location={props.location} hideSideMenu={true}>
    <S.Container>
      <S.Wrap>
        <H1 color={Colors.themeColors.textColor}>Athlete Sign Up</H1>
        <QueryParamMessage />
        <LiveRegister context="live" />
      </S.Wrap>
    </S.Container>
  </Layout>
)

export default withErrorHandler(SignUpPage)
