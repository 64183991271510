import React from 'react'
import s from 'styled-components'

import { Row, Col, getFontSize } from '../../shared'
import { BLACK_ALPHA, OUTLINE, GRAY_2 } from '../../../constants/colors'
import {
  BORDER_RADIUS_LG,
  SHORT_ANIMATION_DURATION,
  minWidth,
  DESKTOP,
  M1,
  M2,
} from '../../../constants/measurements'
import { BOLD_FONT_WEIGHT } from '../../../constants/fonts'
import Typography from '@material-ui/core/Typography'
import * as Colors from '../../../constants/colors'

interface IBtnSelectTagProps {
  active: boolean
}

const BtnSelectOptionTag = s.a<IBtnSelectTagProps>`
  width: 100%;
  display: block;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: ${BORDER_RADIUS_LG} !important;
  border-color: #C4C4C4;
  line-height: 1;
  padding: ${M2} 0;
  margin-bottom: 1rem;
  cursor: pointer;
  font-weight: ${BOLD_FONT_WEIGHT};
  transition: all ${SHORT_ANIMATION_DURATION}ms ease;
  letter-spacing: 1px;

  font-size: ${getFontSize({ normal: 0.96 })};

  ${minWidth(DESKTOP)} {
    padding: ${M2} 0;
    font-size: ${getFontSize({ normal: 1.1 })}
  }


  &:active {
    background: ${Colors.themeColors.primary};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${OUTLINE};
  }

  ${({ active }): string =>
    active
      ? `
      color: ${Colors.themeColors.primary};
      border-color: ${Colors.themeColors.primary};
      background: ${Colors.themeColors.primary};

      &:active {
        color: ${Colors.themeColors.primary};
        border-color: ${Colors.themeColors.primary};
      }`
      : `
      color: ${GRAY_2};

      &:hover
      {
        color: ${GRAY_2};
        border-color: ${BLACK_ALPHA(0.99)};
      }`}
`

interface IBtnSelectOption {
  key: string
  value: string
  helperText?: string
}

interface IBtnSelectProps {
  label?: string
  options: IBtnSelectOption[]
  values: string[]
  numCols?: number
  onClick: (option: string[]) => void
  isSingleSelect?: boolean
  testId?: string
}

interface IBtnSelectOptionProps {
  option: IBtnSelectOption
  values: string[]
  onClick: (option: string[]) => void
  isSingleSelect?: boolean
  testIdWithIndex?: string
}

const BtnSelectOption = ({
  values,
  option,
  onClick,
  isSingleSelect,
  testIdWithIndex,
}: IBtnSelectOptionProps): React.ReactElement => {
  const active = values.includes(option.value)

  const handleClick = (): void => {
    if (isSingleSelect) {
      return onClick([option.value])
    }

    const newValues = active
      ? values.filter(value => value !== option.value)
      : values.concat([option.value])

    return onClick(newValues)
  }

  const handleKeyPress = (event: React.KeyboardEvent): void => {
    event.stopPropagation()
    event.preventDefault()
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick()
    }
  }

  return (
    <>
      <BtnSelectOptionTag
        active={active}
        onClick={handleClick}
        tabIndex={0}
        key={`${active}`}
        onKeyPress={handleKeyPress}
        data-test={testIdWithIndex}
      >
        <Typography
          style={{
            color: active ? 'white' : Colors.themeColors.textColor,
            fontSize: 16,
          }}
        >
          {option.key}
        </Typography>
      </BtnSelectOptionTag>
      {option.helperText && (
        <Typography
          style={{
            color: Colors.themeColors.textColor,
            fontSize: 13,
            marginTop: -13,
            marginBottom: 15,
            fontStyle: 'italic',
          }}
        >
          {option.helperText}
        </Typography>
      )}
    </>
  )
}

export const BtnSelect = ({
  label,
  options,
  values,
  onClick,
  numCols = 3,
  isSingleSelect = false,
  testId,
}: IBtnSelectProps): React.ReactElement => (
  <>
    <Typography
      style={{
        color: Colors.themeColors.textColor,
        fontSize: 16,
        marginBottom: 15,
        marginTop: 10,
      }}
    >
      {label}
    </Typography>
    <Row alwaysFlex margin={M1}>
      {options.map((option, index) => (
        <Col
          sm={12 / numCols}
          md={12 / numCols}
          key={`${option.key}-${option.value}`}
          margin={M1}
        >
          <BtnSelectOption
            testIdWithIndex={`${testId}${index}`}
            values={values}
            option={option}
            onClick={onClick}
            isSingleSelect={isSingleSelect}
          />
        </Col>
      ))}
    </Row>
  </>
)
